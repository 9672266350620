import pimg1 from '../images/portfolio/1.jpg'
import pimg2 from '../images/portfolio/2.jpg'
import pimg3 from '../images/portfolio/3.jpg'
import pimg4 from '../images/portfolio/4.jpg'
import pimg5 from '../images/portfolio/15.jpg'
import pimg6 from '../images/portfolio/13.jpg'
import pimg7 from '../images/portfolio/12.jpg'
import pimg8 from '../images/portfolio/24.jpg'
import pimg9 from '../images/portfolio/14.jpg'
import pimg10 from '../images/portfolio/17.jpg'

import pSimg1 from '../images/portfolio/1.jpg'
import pSimg2 from '../images/portfolio/2.jpg'
import pSimg3 from '../images/portfolio/3.jpg'
import pSimg4 from '../images/portfolio/4.jpg'
import pSimg5 from '../images/portfolio/15.jpg'
import pSimg6 from '../images/portfolio/13.jpg'
import pSimg7 from '../images/portfolio/12.jpg'
import pSimg8 from '../images/portfolio/24.jpg'
import pSimg9 from '../images/portfolio/14.jpg'
import pSimg10 from '../images/portfolio/17.jpg'



const Projects = [
    {
        id: '1',
        title:'Maria & Robin',
        pimg1:pimg1,
        pSimg:pSimg1,
        date:'Feb 2019',
        location:'Austria',
    },
    {
        id: '2',
        title:'Rose & Harry',
        pimg1:pimg2,
        pSimg:pSimg2,
        date:'Jan 2019',
        location:'Austria',
    },
    {
        id: '3',
        title:'Aliana & David',
        pimg1:pimg3,
        pSimg:pSimg3,
        date:'Mar 2020',
        location:'Austria',
    },
    {
        id: '4',
        title:'Aliza & Ken',
        pimg1:pimg4,
        pSimg:pSimg4,
        date:'Apr 2020',
        location:'Austria',
    },
    {
        id: '5',
        title:'Pritam & Ravina',
        pimg1:pimg7,
        pSimg:pSimg7,
        date:'Jun 2022',
        location:'Austria',
    },
    {
        id: '6',
        title:'Jony & Shopna',
        pimg1:pimg8,
        pSimg:pSimg8,
        date:'Jun 2022',
        location:'Austria',
    },
    {
        id: '7',
        title:'Rahul & Priyanka',
        pimg1:pimg5,
        pSimg:pSimg5,
        date:'Jan 2022',
        location:'Austria',
    },
    {
        id: '8',
        title:'Debu & Shonali',
        pimg1:pimg9,
        pSimg:pSimg9,
        date:'Jun 2022',
        location:'Austria',
    },
    {
        id: '9',
        title:'Dipto & Rakhi',
        pimg1:pimg10,
        pSimg:pSimg10,
        date:'Jun 2022',
        location:'Austria',
    },
    {
        id: '10',
        title:'Puja & Suvro',
        pimg1:pimg6,
        pSimg:pSimg6,
        date:'Jun 2022',
        location:'Austria',
    },
]
export default Projects;