import React from "react";
import { Link } from "react-router-dom";
import coupleImg1 from "./../../images/alonso/14.webp";
// import coupleImg2 from "../../images/couple/img-2.png";
// import coupleImg3 from "../../images/couple/shape.png";

const CoupleSection = (props) => {
  return (
    <section className={`wpo-couple-section section-padding ${props.cClass}`}>
      <h1 className="text-center my-4">Código de Vestimenta</h1>
      <div className="container">
        <div className="couple-area clearfix d-flex justify-content-center">
          <div className="row align-items-center">
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="couple-text">
                  <p>Etiqueta formal</p>
                  {/* <div className="social">
                    <ul>
                      <li>
                        <Link to="/home3">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/home3">
                          <i className="ti-twitter-alt"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/home3">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
                {/* <h3 className="m-4">Paleta de colores sugerida: :</h3> */}
                {/* <div className="">
                  <img src={coupleImg1} alt="" />
                </div> */}
                <p>
                  Queremos que disfrutes de la fiesta tanto como nosotros, por
                  eso te hacemos recuerdo amablemente que la invitación es solo
                  para personas adultas. Este día es importante para nosotros,
                  contar con tu presencia lo hace aún más especial... con mucho
                  amor Claudia y Alonso
                </p>
              </div>
            </div>
            {/* <div className="col col-md-2 col-12">
              <div className="middle-couple-shape">
                <div className="shape">
                  <img src={coupleImg3} alt="" />
                </div>
              </div>
            </div>
            <div className="col col-md-5 col-12">
              <div className="couple-item">
                <div className="couple-img">
                  <img src={coupleImg2} alt="" />
                </div>
                <div className="couple-text">
                  <h3>William Jack</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Urna orci auctor vitae nisl. Erat fringilla pellentesque
                    amet tempus. Commodo mi vitae, sagittis blandit.
                  </p>
                  <div className="social">
                    <ul>
                      <li>
                        <Link to="/home3">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/home3">
                          <i className="ti-twitter-alt"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/home3">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoupleSection;
