import React from "react";
import phrase1 from "../../images/alonso/phrase1.webp";
import pImg2 from "../../images/alonso/04.webp";
import pImg3 from "../../images/alonso/05.webp";
import pImg4 from "../../images/alonso/06.webp";
import pImg5 from "../../images/alonso/07.webp";
import pImg6 from "../../images/alonso/08.webp";
import phrase2 from "../../images/alonso/phrase2.webp";
import pImg8 from "../../images/alonso/09.webp";
import pImg16 from "../../images/alonso/16.webp";
import pImg17 from "../../images/alonso/17.webp";
import pImg18 from "../../images/alonso/18.webp";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Portfolios = [
  {
    Pimg: phrase1,
  },
  {
    Pimg: pImg2,
  },
  {
    Pimg: pImg3,
  },
  {
    Pimg: pImg4,
  },
  {
    Pimg: pImg5,
  },
  {
    Pimg: pImg6,
  },
  {
    Pimg: phrase2,
  },
  {
    Pimg: pImg8,
  },
  {
    Pimg: pImg16,
  },
  {
    Pimg: pImg17,
  },
  {
    Pimg: pImg18,
  },
];

const PortfolioSection = (props) => {
  return (
    <section
      className={`wpo-portfolio-section-s2 section-padding ${props.prClass}`}
      id="gallery"
    >
      <div className="container">
        <div className="wpo-section-title">
          <h4>Dulces Recuerdos</h4>
          <h2>Nuestros momentos capturados</h2>
        </div>
        <div className="sortable-gallery">
          <div className="gallery-filters"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-grids gallery-container clearfix">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                >
                  <Masonry columnsCount={4} gutter="30px">
                    {Portfolios.map((image, i) => (
                      <div className="grid" key={i}>
                        <div className="img-holder">
                          <img
                            src={image.Pimg}
                            alt={i}
                            style={{ width: "100%", display: "block" }}
                          />
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
