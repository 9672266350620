import React, { Fragment, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/home",
  },
];

const MobileMenu2 = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          <li>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Inicio
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="story"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              Historia
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="event"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              Evento
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="gift"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
            >
              Regalos
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="rsvp"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              Confirmar
            </Link>
          </li>

          <li>
            <Link
              activeClass="active"
              to="gallery"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              Galería
            </Link>
          </li>
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu2;
