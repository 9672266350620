import React from "react";
import LocationMap from "./Modal";

const Hero9 = () => {
  return (
    <section className="mt-4 static-hero-s4 " id="gift">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="wpo-event-item">
                  <div className="text-center">
                    <h2 className="wedding-title">Claudia & Alonso</h2>
                    <ul>
                      <li>Tu presencia es el mejor regalo para nosotros,</li>
                      <li>pero si deseas hacernos un obsequio </li>
                      <li>para nuestra luna de miel</li>
                      <li>puedes hacer una transferencia a:</li>
                      <li>Alonso Velazco Madrid </li>
                      <li>Cuenta en soles:</li>
                      <li>BCP: 19301919437023</li>
                      <li>CCI 00219310191943702314</li>
                      <li>Yape/Plin: 945454454</li>
                      <li>Cuenta en dólares BCP: </li>
                      <li>21536118506135</li>
                      <li>CCI 00221513611850</li>
                    </ul>
                    {/* <ul>
                      <li>
                        Monday, 12 Apr. 2022 <br />
                        1:00 PM – 2:30 PM{" "}
                      </li>
                      <li>4517 Washington Ave. Manchester, Kentucky 39495</li>
                      <li>+1 234-567-8910</li>
                      <li>
                        <LocationMap />
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero9;
