import React from "react";
import { Link } from "react-router-dom";
import men from "./../../images/alonso/men.webp";
import women from "./../../images/alonso/women.webp";
import coupleImg2 from "../../images/couple/img-2.png";
import coupleImg3 from "../../images/couple/shape.png";

const FamilySection = (props) => {
  return (
    <section
      className={`wpo-couple-section section-padding ${props.cClass}`}
      id="family"
    >
      <div className="container">
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-md-5 col-12">
              <div className="couple-item">
                <div className="couple-text">
                  <h3>Nuestros Padres </h3>
                  <div className="">
                    <img className="p-4" src={women} height={250} alt="" />
                  </div>
                  <p>Luis Edson Alvizuri Tupayachi</p>
                  <p>Martha Checa Vasquez</p>
                  <div className="">
                    <img className="p-4" src={men} height={250} alt="" />
                  </div>
                  <p>Carlos Antonio Velazco Bravo</p>
                  <p>Amparo Iris Madrid Rodrigo</p>
                </div>
              </div>
            </div>
            <div className="col col-md-2 col-12">
              <div className="middle-couple-shape">
                <div className="shape">
                  <img className="love-img" src={coupleImg3} alt="" />
                </div>
              </div>
            </div>
            <div className="col col-md-5 col-12">
              <div className="couple-item">
                <div className="couple-text">
                  <div className="couple-text">
                    <h3>Nuestros Padrinos</h3>
                    <p>Cecilia Alvizuri Tupayachi</p>
                    <p>Jose Diaz Chavez</p>
                    <h3>Nuestro Padrino de Aros</h3>
                    <p>Jonathan Velazco Madrid</p>
                    <h3>Nuestros Testigos</h3>
                    <p>Fabiola Carpio Montesinos</p>
                    <p>Raul Orihuela Abril</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FamilySection;
