import React from "react";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/alonso/11.webp";
import sImg2 from "../../images/alonso/12.webp";
// import sImg3 from "../../images/event/3.jpg";
import LocationMap from "./Modal";

const Events = [
  {
    Simg: sImg1,
    title: "Ceremonia Religiosa",
    li1: "Sábado 04 de mayo de 2024, inicio 12:45 pm",
    li2: "Parroquia San Juan Bautista de Yanahuara - Junto a los portales de Yanahuara - Arequipa",
    li3: 1,
  },
  {
    Simg: sImg2,
    title: "Recepción",
    li1: "",
    li2: "Acompáñanos después de la ceremonia religiosa en el Salón de eventos Laguna Dorada - Av Primavera S/N altura de km 6 de la Av. Aviacion, Cerro Colorado - Arequipa",
    li3: 2,
  },
  // {
  //     Simg: sImg3,
  //     title: 'Wedding Party',
  //     li1: 'Monday, 12 Apr. 2022 1:00 PM – 2:30 PM',
  //     li2: '4517 Washington Ave. Manchester, Kentucky 39495',
  //     li3: '+1 234-567-8910',
  // },
];

const EventSection = (props) => {
  return (
    <section className="wpo-event-section section-padding pb-4 " id="event">
      <div className="container">
        <SectionTitle
          subTitle={"Cuando y Donde"}
          MainTitle={"Cuando y Donde"}
        />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-6 col-md-6 col-12 d-flex " key={eitem}>
                <div className="wpo-event-item d-flex align-items-center justify-content-center flex-column">
                  <div className="wpo-event-img">
                    <img src={event.Simg} alt="" />
                    <div className="title">
                      <h2>{event.title}</h2>
                    </div>
                  </div>
                  <div className="wpo-event-text ">
                    <ul>
                      <li>{event.li1}</li>
                      <li>{event.li2}</li>
                      {/* <li>{event.li3}</li> */}
                      <li>
                        <LocationMap maps={event.li3} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
