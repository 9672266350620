import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

const RSVPFrom2 = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    date: "",
    phone: "",
    meal: "",
    reason: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const [submissionStatus, setSubmissionStatus] = useState(null); // State variable to track submission status
  const [loading, setLoading] = useState(false); // State variable to track loading state

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbyjZvX4XCXXABvxyWLTEbp_G9Wtet5pl4Ud8-k3PEXsYyqD-oBdeUDtMX-ZoYe44MBmXA/exec";

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        date: "",
        phone: "",
        meal: "",
        reason: "",
      });
      setLoading(true); // Set loading state to true when form submission starts

      fetch(scriptUrl, {
        method: "POST",
        body: new FormData(formRef.current),
      })
        .then((res) => {
          console.log("SUCCESSFULLY SUBMITTED");
          setSubmissionStatus("success"); // Set submission status to success
          setLoading(false); // Set loading state to false after form submission completes
        })
        .catch((err) => {
          console.log(err);
          setSubmissionStatus("error"); // Set submission status to error
          setLoading(false); // Set loading state to false after form submission completes
        });
    } else {
      validator.showMessages();
    }
  };

  const formRef = useRef(null);

  return (
    <div>
      <form
        onSubmit={(e) => submitHandler(e)}
        ref={formRef}
        method="post"
        name="google-sheet"
        className="contact-validation-active"
      >
        <div className="row">
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.name}
                type="text"
                name="name"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Tus Nombres y Apellidos"
              />
              {validator.message("name", forms.name, "required|alpha_space")}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.email}
                type="email"
                name="email"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Tu Correo Email"
              />
              {validator.message("email", forms.email, "required|email")}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.phone}
                type="text"
                name="phone"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Tu Celular"
              />
              {validator.message("phone", forms.phone, "required")}
            </div>
          </div>
          {/* <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.reason}
                type="text"
                name="reason"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="What Will You Be Attending"
              />
              {validator.message("reason", forms.email, "required")}
            </div>
          </div> */}
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <select
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                value={forms.meal}
                type="text"
                className="form-control"
                name="meal"
              >
                <option>¿Eres alérgica (a) algo?</option>
                <option>Si</option>
                <option>No</option>
              </select>
              {validator.message("meal", forms.meal, "required")}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="radio-buttons">
              <p>
                <input
                  type="radio"
                  id="attend"
                  name="radio-group"
                  defaultChecked
                  value="Sí, estaré allí"
                />
                <label htmlFor="attend">Sí, estaré allí</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="not"
                  name="radio-group"
                  value="Lo siento, no puedo ir"
                />
                <label htmlFor="not">Lo siento, no puedo ir</label>
              </p>
            </div>
          </div>
        </div>
        <div className="submit-area">
          <button type="submit" className="theme-btn mb-4" disabled={loading}>
            {loading ? "Enviando mensaje ..." : "Enviar"}
          </button>
          {/* Display submission message based on submission status */}
          {submissionStatus === "success" && (
            <div className="alert alert-success">
              ¡Formulario enviado exitosamente!
            </div>
          )}
          {submissionStatus === "error" && (
            <div className="alert alert-danger">
              Error al enviar el formulario. Por favor, inténtelo de nuevo más
              tarde.
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default RSVPFrom2;
