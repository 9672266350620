import React from "react";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/alonso/02.webp";
import sImg2 from "../../images/alonso/03.webp";
import sImg3 from "../../images/alonso/13.webp";

import shape from "../../images/story/shape.png";
import VideoModal from "../ModalVideo/VideoModal";

const Storys = [
  {
    sImg: sImg1,
    title: "Cuando nos conocimos",
    date: "14 de agosto del 2022",
    description:
      "Nos conocimos sin buscarnos, pero solo basto esa única vez para darnos cuenta que estábamos en el lugar indicado.",
  },
  {
    sImg: sImg2,
    title: "Nuestra Primera Cita",
    date: "22 de agosto del 2022",
    description:
      "Dos ciudades distintas y cientos de kilómetros no fueron impedimento para encontrarnos nuevamente y caer enamorados el uno del otro de manera instantánea.",
  },
  {
    sImg: sImg3,
    title: "Ella dijo que Si!",
    date: "20 de febrero 2023",
    description:
      "Nunca estuvimos más seguros de dar un paso tan importante como este, todo confabuló  para que ese día sea perfecto y ante el inmenso océano y un cálido atardecer, ella dijo que sí!",
  },
];

const StorySection = (props) => {
  return (
    <section
      className={`wpo-story-section section-padding ${props.stClass}`}
      id="story"
    >
      <div className="container-fluid">
        <SectionTitle
          subTitle={"Nuestra Historia"}
          MainTitle={"Nuestra Dulce Historia de Amor"}
        />
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="wpo-story-img-wrap">
                <div className="wpo-story-img">
                  <img src={story.sImg} alt="" />
                </div>
                <div className="wpo-img-shape">
                  <img src={shape} alt="" />
                </div>
              </div>
              <div className="wpo-story-content">
                <div className="wpo-story-content-inner">
                  <h2>{story.title}</h2>
                  <span>{story.date}</span>
                  <p>{story.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StorySection;
