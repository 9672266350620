import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.svg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <div className="wpo-site-footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-image">
              <Link className="logo" to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="footer-link">
              <ul>
                <li>
                  <Link to="/">Inicio</Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="gallery"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                  >
                    Galería
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/home2">RSVP</Link>
                </li>
                <li>
                  <Link to="/portfolio-grid">Apoyo</Link>
                </li>
                <li>
                  <Link to="/blog">Presencia</Link>
                </li>
                <li>
                  <Link to="/shop">Fecha</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                  </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="link-widget">
              <ul>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    <i className="ti-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    <i className="ti-dribbble"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    <i className="ti-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    <i className="ti-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/">
                    <i className="ti-skype"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-12">
            <div className="copyright">
              <p>
                © Copyright 2024 |{" "}
                <Link to="//zalogarciam.com">Crafted by ZG</Link> | Todos los
                derechos reservados..
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
